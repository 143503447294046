@import 'src/styles/onboarding_theme.scss';

#countries-multi-select {
  .select-box {
    display: flex;
  }

  .selected-country {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    justify-content: space-between;
  }
}
